<template>
  <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
           <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>
          <div class="text-xl font-semibold text-green-500 ">{{$t('import_orders')}}</div>
        </div>
      </div>

        <div class="mx-6 md:flex mb-6 border p-5 border-dotted rounded-md border-gray-500">
                        <div v-if="sellers.length > 0" class="md:w-1/2 px-3 mb-6 md:mb-0">
                            <div class="border-2 h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                    <label for="seller" class="bg-white text-gray-700 px-1">{{$t('select_seller')}} <span :class="$colors.required">*</span></label>
                                    </p>
                                </div>
                                <p>
                                  <v-select label="fullName" :options="sellers" @input="setSelected"> </v-select>
                                </p>
                            </div>
                        </div>
                        <div class="md:w-1/2 px-3">
                             <div class="border-2 h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                    <label for="seller" class="bg-white text-gray-700 px-1">Country ({{$t('warehouse')}}) <span :class="$colors.required">*</span></label>
                                    </p>
                                </div>
                                <p>
                                 <v-select
                                    @input="setSelectedW"
                                    :options="warehousesUser"
                                    label="countryName"
                                                    >
                                    <template slot="option" slot-scope="option">
                                      <img
                                        class="flag-img"
                                        :src="$f.getCountryCode(option.country)"
                                      />
                                      {{ option.countryName }}
                                    </template>
                                  </v-select>
                                </p>
                            </div>
                        </div>
                    </div>

      <div class="px-6 mt-3">
        <div class="relative">
         <loading :active.sync="loading" :is-full-page="fullPage"></loading>
         <div class="border border-dashed border-gray-500">
          <input
           accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="processFile($event)"
            ref="csvOrdersFile"
            type="file"
            class="cursor-pointer relative block opacity-0 w-full h-full p-20 z-50"
          />
          <div class="text-center p-10 absolute top-0 right-0 left-0 m-auto">
            <h4>{{$t('drag_file')}}</h4>
            <p v-if="this.selectedFileName" class="font-bold">
              {{$t('selected_file')}}
              <span class="text-green-500 ">{{ this.selectedFileName }}</span>
            </p>
          </div>
        </div>
        <div class="p-5 grid justify-items-center">
          <button @click="uploadOrders"  icon="publish" :disabled="loading"
            class="flex items-stretch rounded-md mx-1 border border-gray-100 bg-green-600  hover:bg-green-600  text-white py-2 px-4 focus:outline-none loidingconfirm relative" :class="loading?'noselect':''">
            <loading :active.sync="loading" :is-full-page="fullPage"></loading>
            <i class="self-center material-icons">publish</i> {{$t('upload')}}
          </button>
        </div>
        </div>
         <div v-if="messages.length > 0">
        <table class="min-w-full leading-normal table-messages">
          <thead>
            <tr>
              <th
                class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
              >
                {{$t('orderid')}}
              </th>

              <th
                class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
              >
                {{$t('message')}}
              </th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in messages" :key="index">
            <tr>
              <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                <div
                  class="text-xs leading-5 capitalize px-2 font-semibold rounded-full text-gray-900"
                >
                  {{ item.orderId }}
                </div>
              </td>

              <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                <div
                  :class="item.type"
                  class="text-xs leading-5 capitalize px-2 font-semibold rounded-full text-gray-900"
                >
                  {{ item.text }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
        <!--p>The csv file should be structered like example below:</p-->
        <button
            @click="downloadcsvExample"
            icon="publish"
            class="flex items-stretch rounded-md mx-1 border border-gray-100 bg-green-600  hover:bg-green-600  text-white py-2 px-4 focus:outline-none"
          >
            <i class="self-center material-icons">file_download</i> {{$t('download_xlsx_example')}}
          </button>

          <!-- <button
            @click="downloadxlsxExample"
            icon="publish"
            class="flex items-stretch rounded-md mx-1 border border-gray-100 bg-green-600  hover:bg-green-600  text-white py-2 px-4 focus:outline-none"
          >
            <i class="self-center material-icons">file_download</i> {{$t('download_xlsx_example')}}
          </button> -->

        <!--img src="../../assets/csvFileExample.png" /-->
      </div>

      <br />

     
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx"

export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
      formData: {warehouse:null,idWharhouse:null},
      messages: [],
      //currentUser:{},
      sellers: [],
      warehouses:[],
      warehousesUser:[],
       loading:false,
       fullPage: false,
      selectedFileName: "",
      selectContry:"",
     // idWharhouse:""

    };
  },
  computed: {
      warhouseSelected(){
        return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
      },
   },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
          // await this.getwharhouseId();
           //await this.getCurrentSeller();
      },
      'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
            await this.getCurrentSeller();
      }
  },
  async mounted() {
   // await this.getwharhouseId();
    await this.getCurrentSeller();
   // await this.getWarehouses();
  },
  methods: {
    RouterBack: function() {
       this.$router.back();
   },
    async getWarehouses(){
        const filter = {limit:10};
        const res = await this.$server.search("warehouses",filter);
        if (res && res.content.results) {
            this.warehouses = res.content.results;
        } else this.warehouses = [];
    }, 
    async setSelectedW(contry) {
       if(contry){
         this.formData.warehouse=contry.country;
         this.formData.idWharhouse=contry._id;

       }else{
          this.formData.warehouse=null;
          this.formData.idWharhouse=null;

       } 
    },
    async getwharhouseId(){
      await this.$store.dispatch('wharhouse/getwharhouseId',{country:await this.warhouseSelected}).then((res) => {
              this.idWharhouse=res;
              this.formData.idWharhouse=res;
          })
    },
    setSelected(seller) {
      if (seller) {
        this.formData.seller = seller;
      } else {
        this.formData.seller = "";
      }
    },
    async save() {},
    async processFile(event) {
      if (event.target.files.length) {
         var reader = new FileReader();
          let ExtensionFile=event.target.files[0].name.split('.').pop();
         console.log('event.target.files[0]',event.target.files[0].type);
        if(ExtensionFile=="csv"){
          //csv file
          this.selectedFileName = event.target.files[0].name;
          //this.formData.file = event.target.files[0];
         
          reader.onload = async (e) => {
            //console.log(e);
          this.formData.file = e.target.result.split(",")[1];
          this.formData.type = "Csv";
          };
          reader.readAsDataURL(event.target.files[0]);

        }else{
            reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            this.selectedFileName = event.target.files[0].name;
            const wb = XLSX.read(bstr, { type: 'binary', cellDates: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            this.formData.file = data.filter(function(x){return x.length});
            this.formData.type = "Excel";
          }

          reader.readAsBinaryString(event.target.files[0]);
        }
        
      } else {
        this.formData.file = "";
        this.selectedFileName = "";
      }
      this.messages = [];
    },
    getElementInArray(data,value){
        var result  = data.filter(function(o){
            let whar=o.warehouse;
            if(o.warehouse._id) whar=o.warehouse._id;
            return whar == value.toString();
        } );
        return result? result[0] : null; // or undefined
    },
    getElementInArrayByCountry(data,value){
        console.log('details pr----------',data)
        console.log('warehouseID----------',value)

        var result  = data.filter(function(o){
            return o.warehouse._id.toString() === value.toString() || (o.warehouse.parent && o.warehouse.parent._id.toString() === value.toString());
        });
      //  console.log('result warehouse--------',result)

        if(result){
            console.log('qte=====>',result.reduce((n, {quantity}) => n + quantity.inStock, 0));
            return {outOfStock:result.find(z => z.warehouse._id.toString() == value.toString()).outOfStock,quantity: { total: result.reduce((n, {quantity}) => n + quantity.total, 0), inStock: result.reduce((n, {quantity}) => n + quantity.inStock, 0), defective: result.reduce((n, {quantity}) => n + quantity.defective, 0) }};
        } 
       
        return result? result : null; // or undefined
    },
     async getStatusByName(name) {
        const result = await this.$server.get("status",{  name: name  });
        return result.content._id;
    },
    async uploadOrders() {
      this.loading=true;
      if (this.formData.file && this.formData.seller && this.formData.warehouse) {
        let dataWhrahouseSetting=null;
          if(this.formData.seller.settings.length>0) dataWhrahouseSetting= this.getElementInArray(this.formData.seller.settings,this.formData.idWharhouse);
          if(!dataWhrahouseSetting || (dataWhrahouseSetting && !dataWhrahouseSetting.confirmation && !dataWhrahouseSetting.fulfillement && !dataWhrahouseSetting.shipping)){
            alert("vous n'avait pas activé aucun services veuillez contacter administration rmExpress", "warning");
             this.loading=false;
            return false;
        } 

        const outOfStockStatus = await this.getStatusByName("OutOfStock");
        const pending = await this.getStatusByName("Pending");

        let messagesorders = [];
        const nDate = new Date().toLocaleString('en-US', {timeZone: 'Africa/Casablanca'});
       // console.log('formdata------',this.formData.seller)
         if(this.formData.seller.status != 'active'){ 
              alert("Cette user n'est pas active contacter  rmExpress pour l'activer merci", "warning");
              this.loading=false;
              return false
          }
          
        let order = { seller: this.formData.seller,warehouse:this.formData.warehouse._id||this.formData.warehouse };
        let orderslines=[];
        if (this.formData.file.length==0) { //check if empty!
              alert('Empty file!', "error");
              this.loading=false;
              return false;
        }
        if (this.formData.file.length < 2) { //check length if >= 2! (2 header + at least 1 order)
              alert('File contains only the header!', "error");
              this.loading=false;
              return false;
        }

        this.loading=true;
        //console.log('oook')
       // return false
        this.itemsOrders=0;
        this.showprogress = true;
        //this.$Progress.start();
        //check file organized
        orderslines=this.formData.file;
         orderslines.forEach(function (element, i) {
            const elementrow = element.filter(function (el) {return el;});
            if(elementrow.length>10){
                let message = {
                    orderId: element[0],
                    text: `Order not properly organized in line ${i+1}, probably the customer information consists of (,) replace it with (;) and try again`,
                    type: 'error',
                    sort:i+1
                };
                messagesorders.push(message);
                // console.log('orderslines------------',orderslines)
                // console.log('element------------',element)
                orderslines = orderslines.filter(function( obj ) {return obj[0].toString() !== element[0].toString()}.bind(this));    
            }else element.push(i+1)
        });
        // console.log("orderslines 00",orderslines)
        //regroupé row by group same product
         var ress = orderslines.reduce(function(ress, currentValue) {
            if (ress.findIndex(i => (i.name === currentValue[2])) === -1 && currentValue[8] && currentValue[8].toString().toLowerCase() != 'quantity') {
                ress.push({name:currentValue[2]});
            }
            return ress;
        }, []).map(function(group) {
            return {
                product: group,
                datas: orderslines.filter(function(_el) {
                  return  _el[2] === group.name;
               }).map(function(_el) { return {id:_el[0].toString().trim(),order_id:_el[0].toString().trim(),date:new Date(nDate),product_name:_el[2]?_el[2].toString().trim():'','customer.fullName':_el[3]?_el[3].toString().trim():null,'customer.phone':_el[4]?_el[4].toString().replace(/[^|\d]/g, ''):null,'customer.country':order.warehouse,'customer.address':_el[5]?_el[5].toString():null,'customer.shippingAddress':_el[5]?_el[5].toString():null,'customer.city':_el[6]?_el[6].toString():null,price:_el[7],quantity:_el[8]?_el[8]:null,source:_el[9]?_el[9]:null,seller:order.seller,totalPrice:_el[7],details:[],line:_el[10]||_el[9]} })
            }
        });
               console.log("orderslines",orderslines)
                console.log("ress",ress)

        //insert data
        for (let i = 0; i < ress.length; i++) {
            let resP  = await this.$server.get("products", {ProductBynameAndId:true,warehouse:this.formData.idWharhouse._id||this.formData.idWharhouse,id:ress[i].product.name,name:ress[i].product.name,seller:this.formData.seller._id || this.formData.seller });
            if (!resP.content || (resP.content && !resP.content._id)) {
                ress[i].datas.forEach(function (element) {
                    let message = {
                        orderId: element.order_id,
                        text: `Product id: ${element.product_name} in line ${element.line} not exist`,
                        type: 'error',
                        sort:element.line
                    };
                    messagesorders.push(message);
                 });
            }else{
                ress[i].inStock=0;
                ress[i].productID=resP.content._id;
                ress[i].outOfStock=true;
                //  console.log('resP content',resP.content)
                // console.log('resP.content.details',resP.content.details)

                const dataWhrahousePr=this.getElementInArrayByCountry(resP.content.details,this.formData.idWharhouse._id||this.formData.idWharhouse);  

                if(dataWhrahousePr && !dataWhrahousePr.outOfStock ) {
                  ress[i].inStock=dataWhrahousePr.quantity.inStock;
                  ress[i].outOfStock=dataWhrahousePr.outOfStock;
                }

                // if(resP.content.details.length>0){
                //   ress[i].inStock=resP.content.details[0].quantity.inStock;
                //   ress[i].outOfStock=resP.content.details[0].outOfStock;
                // }
                console.log('ress[i]',ress[i].datas)

               console.log('lodash data-------',_.uniqBy(ress[i].datas, 'order_id')) 
               //remove duplicated order by ids 
               const UniquedataOrder_id= ress[i].datas.map(e => e['order_id'].toString()).map((e, i, final) => final.indexOf(e) === i && i).filter(obj=> ress[i].datas[obj]).map(e => ress[i].datas[e]);
               // console.log('UniquedataOrder_id11111',UniquedataOrder_id)
                //get duplicated order by ids 
                const duplicateDataOrder = ress[i].datas.map(e => e['order_id'].toString()).map((e, i, final) => final.indexOf(e) !== i && i).filter(obj=> ress[i].datas[obj]).map(e => ress[i].datas[e])
               // console.log('duplicateDataOrder',duplicateDataOrder)

                ress[i].datas=_.uniqBy(ress[i].datas, 'order_id');
                // console.log(' ress[i].datas', ress[i].datas)
                // return false
                duplicateDataOrder.forEach(function (orderD) {
                      let message = {
                          orderId: orderD.order_id,
                          text: `Order id ${orderD.order_id} in line ${orderD.line} duplicated`,
                          type: 'warn',
                          sort:orderD.line
                      };
                      messagesorders.push(message);   
                  }.bind(this));

                //CHECK DOUBLE ORDER BY PRODUCT && PHONE
                  let duplicates = [];
                  let tempArray = [];

                  ress[i].datas.forEach((element, key) => {
                    let isAlreadyAdded = tempArray.find(
                        (item) => item.product_name && element.product_name && item.product_name === element.product_name && item["customer.phone"] && element["customer.phone"] && item["customer.phone"] === element["customer.phone"]
                    );
                    if (!isAlreadyAdded)  tempArray.push(element); 
                    else duplicates.push(element);
                  });

                  duplicates.forEach(function (orderD) {
                      let message = {
                          orderId: orderD.order_id,
                          text: `Order in line ${orderD.line}, contient produit et telephone existe deja dans un autre commande`,
                          type: 'warn',
                          sort:orderD.line
                      };
                      messagesorders.push(message);   
                     ress[i].datas = ress[i].datas.filter(function( obj ) {return (obj.order_id).toString() !== orderD.order_id;}.bind(this)); 
                  }.bind(this));

                  //END / CHECK DOUBLE ORDER BY PRODUCT && PHONE
                   

                var x = ress[i].datas.map(el => el.order_id);
                let dataOrders=[];
                var itemsCounts=100;
                var count = Math.ceil(x.length/itemsCounts);
                console.log('x count---------',count)
                var close=false;
                for (var d = 0; d < count; d++) {
                  if(close) return;
                  var s= x.slice(d*itemsCounts,d*itemsCounts + itemsCounts)
                  console.log('s count---------',s)
                  const checkedOrders = await this.$server.bigSearch("orders", {byIds:s,'customer.country':this.formData.warehouse ,limit:10});
                  console.log('x checkedOrders---------',checkedOrders)
                  if(checkedOrders && checkedOrders.content){
                        if(checkedOrders.content.results && checkedOrders.content.results.length>0)  {
                          for (let p in checkedOrders.content.results) {
                             dataOrders.push(checkedOrders.content.results[p])
                          }
                        }
                  }else close=true;

                  if(close){alert("Une erreur s'est produite, Le nombre des commandes est peut-être trop élevé. Veuillez résoudre le problème et réessayer.","warning")}

                }

                 //console.log('x order_________',x)
             //   const checkedOrders = await this.$server.bigSearch("orders", {byIds:x ,limit:10000});
                
                //console.log('check order_________',checkedOrders)
                //return false
                if (dataOrders && dataOrders.length>0) {
                    // console.log('checkedOrders 11 ids____________',checkedOrders);
                    // console.log('x 11 ids____________',x);
                    dataOrders.forEach(function (element) {
                       let filterObj = ress[i].datas.filter(function(e) {return (e.order_id).toString() == element;}.bind(this));
                       // console.log('filterObj order_________',filterObj)
                      if(filterObj.length>0){
                       let message = {
                            orderId: element,
                            text: `Order ID in line ${filterObj[0].line} already exist in the system`,
                            type: 'warn',
                            sort:filterObj[0].line
                        };
                        messagesorders.push(message);
                      }
                    ress[i].datas = ress[i].datas.filter(function( obj ) {return (obj.order_id).toString() !== element;}.bind(this));    
                   }.bind(this));
                   
                }
                //check phone number and quantity
                ress[i].datas.forEach(function (element) {
                    if(!element['customer.phone'] || !element['customer.fullName'] || !element['customer.address'] || !element.quantity || !element.source){
                        let feild="Phone number";
                        if(!element['customer.fullName']) feild="Customer fullName";
                        if(!element['customer.address']) feild="Address";
                        if(!element.quantity) feild="Quantity";
                        if(!element.source) feild="Store";
                        let message = {
                            orderId: element.order_id,
                            text: `${feild} is required in line ${element.line}`,
                            type: 'error',
                            sort:element.line
                        };
                        messagesorders.push(message);
                        ress[i].datas = ress[i].datas.filter(function( obj ) {return (obj.order_id).toString() !== element.order_id;}.bind(this)); 
                    }
                   if(element.quantity && !isNaN(element.quantity) && parseInt(element.quantity)>10){
                        let message = {
                            orderId: element.order_id,
                            text: `Check the quantity you entered is too large in line ${element.line}`,
                            type: 'error',
                            sort:element.line
                        };
                        messagesorders.push(message);
                        ress[i].datas = ress[i].datas.filter(function( obj ) {return (obj.order_id).toString() !== element.order_id;}.bind(this));    
                   }
                   if(element.quantity && isNaN(element.quantity)){
                        let message = {
                            orderId: element.order_id,
                            text: `Check the quantity you entered, it must be a number in line ${element.line}`,
                            type: 'error',
                            sort:element.line
                        };
                        messagesorders.push(message);
                        ress[i].datas = ress[i].datas.filter(function( obj ) {return (obj.order_id).toString() !== element.order_id;}.bind(this));    
                   }
                   if(element.price && isNaN(element.price)){
                        let message = {
                            orderId: element.order_id,
                            text: `Check the price you entered, it must be a number in line ${element.line}`,
                            type: 'error',
                            sort:element.line
                        };
                        messagesorders.push(message);
                        ress[i].datas = ress[i].datas.filter(function( obj ) {return (obj.order_id).toString() !== element.order_id;}.bind(this));    
                   }

                   let productLine = {};
                   
                   
                   productLine.product = ress[i].productID; // get product id from product _id !! 
                   productLine.unitPrice = 0;
                   if(element.totalPrice && element.quantity) productLine.unitPrice = element.totalPrice/element.quantity;
                   productLine.quantity = element.quantity; // check the qté and stock to detect status! //imho
                   element.details.push(productLine);
                   element.status = pending;
                   element.statusCallcenter = pending;
                   if (ress[i].outOfStock || parseInt(ress[i].inStock)<1) {
                        element.status = outOfStockStatus;
                        element.statusCallcenter = outOfStockStatus;
                   }
 
                }.bind(this));
                //console.log('res data---------',ress[i].datas)
               
                   if(ress[i].datas.length>0){
                     var itemsCounts=100;
                     var count = Math.ceil(ress[i].datas.length/itemsCounts);
                     var close=false;
                     for (var o = 0; o < count; o++) {
                       if(close) return;
                        var s= ress[i].datas.slice(o*itemsCounts,o*itemsCounts + itemsCounts)
                        const insertdatas=await this.$server.insertMany("orders",s);
                        console.log(i,Date.now())
                        if(insertdatas){
                            if(Array.isArray(insertdatas)){
                              this.itemsOrders+=s.length;
                                 s.forEach(function (element) {
                                      let message = {
                                          orderId: element.order_id,
                                          text: 'order created',
                                          type: 'success',
                                          sort:element.line
                                      };
                                      messagesorders.push(message);
                                  }.bind(this));
                            }else{
                                let message = {
                                      orderId: 'The problem ',
                                      text: `Among these orders id, there is a problem {${s.map(el => el.order_id)}} error: ${insertdatas}`,
                                      type: 'error',
                                  };
                                  messagesorders.push(message);
                                  close=true;
                            }
    
                        //await this.reEvaluteOutOfStockOrers({ body: { product: ress[i].productID, orderableQuantity: ress[i].inStock } })
                        }
                        if(close){
                          this.loading=false;
                          this.showprogress = false;
                          this.itemsOrders=0;
                          //this.$Progress.finish();
                        }
                      }  
                   }
            }
          }
          //console.log('Exisxt__Product',messages);
          messagesorders.sort(function (a, b) {return a.sort - b.sort;});

       // const messages = await this.$server.import("orders", this.formData);
        this.loading=false;
        this.showprogress = false;
       
        if(messagesorders.length>0){
           //this.$Progress.finish();
           this.$vm2.open('messages');
            this.messages = messagesorders;
            alert("Upload result is ready!", "default");
            this.formData.file = "";
            this.selectedFileName = "";
        }else {alert("Une Erreur S'est Produite, Veuillez Réessayer Plus Tard", "error")}
      } else {
        const isFilePresent = !!this.formData.file;
        const isSellerPresent = !!this.formData.seller;
        const isWarhousePresent = !!this.formData.warehouse;
        this.loading=false;
        let msg = "";
        if (!isFilePresent || !isSellerPresent  || !isWarhousePresent) {
          msg = "please specify ";
          if (!isFilePresent) {
            msg = msg + "the file";
          }
          if (!isFilePresent && !isSellerPresent) {
            msg = msg + " and ";
          }
           if (!isSellerPresent) {
            msg = msg + "the seller";
          }
           if (!isFilePresent && !isWarhousePresent) {
            msg = msg + " and ";
          }
          if (!isWarhousePresent) {
            msg = msg + "the country";
          }
        }

        if (!!msg) {
          alert(msg);
        }
      }

      this.$refs.csvOrdersFile.value = null;
    }
    ,
    async getCurrentSeller() {
        if (this.currentUser && this.currentUser.type) {
          if (this.currentUser.type != "Seller") await this.getSellers();
          else  this.formData.seller = this.currentUser;

          await this.getWarehouses();
          //console.log('warehouses------',this.warehouses)
          for (let i in this.warehouses) {
            for (let j in this.currentUser.countries) {
            //  console.log('warehouses country------',this.warehouses[i].country)
                if(!this.warehousesUser.find(o => o.country === this.warehouses[i].country) && (this.warehouses[i].country === this.currentUser.countries[j].code||this.currentUser.countries[j])){
                  this.warehousesUser.push(this.warehouses[i]);
                } 
            }
          }
          console.log('warehousesUser------',this.warehousesUser)
        }
    },
    async getSellers() {
        const res = await this.$server.search("users", { type: "Seller",countries:this.idWharhouse.country,limit:10 });
        if (res && res.content.results) {
          this.sellers = res.content.results;
        }else this.sellers=[];
    },
    async downloadcsvExample(){
          let link = window.document.createElement("a");
        // link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI("ORDER ID,DATE,PRODUCT NAME,CONSTUMER NAME,PHONE NUMBER,ADRESSE,CITY,PRICE,QUANTITY,STORE"));
        // link.setAttribute("download", "orders_example.csv");
        // link.click();
        //  link.href = this.ExcekLink;
        //  link.click();
        link.href = "https://storeno.b-cdn.net/saas/files-2-2022/1643717907400.xls";
        link.download = 'orders_exemple.xls';
        link.click();
    },
    async downloadxlsxExample(){
      var blob = new Blob(["ORDER ID,DATE,PRODUCT NAME,CONSTUMER NAME,PHONE NUMBER,ADRESSE,CITY,PRICE,QUANTITY,STORE"], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" });
        var url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        var a = document.createElement("a");
        a.setAttribute("download", "orders_example.xlsx");
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
  },
};
</script>


<style scoped>
.table-messages{
  display: block;
  max-height: 500px;
  overflow: auto;
  margin-bottom: 35px;
}
.error {
  color: #c62525;
}
.success {
  color: #25c631;
}
.warn {
  color: #ff9800;
}
</style>
